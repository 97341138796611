import React, { useState, useEffect, useRef } from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Content = () => {

    const [state, setState] = useState({
        currentIndex: null
    });

    function changeCurrentIndex(event, index) {
        // document.body.style.overflow = 'hidden';
        setState({
            currentIndex: index
        });
    };

    const node = useRef();

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
        document.removeEventListener("mousedown", handleClick);
        };
      }, []);

    const handleClick = e => {
        if(node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click 
        // document.body.style.overflow = 'unset';
        setState({
            currentIndex: null
        });
    };

    return <StaticQuery query={graphql`
    {
        home: wordpressPage(title: { eq: "Home" }){
            acf {
                top_background_image {
                    source_url
                }
            }
        }
        activities: wordpressPage(slug: { eq: "activities" }) {
            acf {
                activities_title
                activities_subtitle
                content
                more_info_button
                main_content_left
                main_content_right
            }	
        }
        allWordpressWpActivities {
            edges {
                  node {
                      id
                      title
                      slug
                      featured_media {
                          source_url
                      }
                      acf {
                        content
                        large_image {
                          source_url
                        }
                        small_image {
                          source_url
                        }
                      }
                  }
              }
          }
    }
    `} render={props => (
        <div className="frame">
            <div className="activities-content">
                <div className="top-background-image">
                    <img src={props.home.acf.top_background_image.source_url} />
                </div>
                <div className="title" data-aos="fade-up">{ props.activities.acf.activities_title }</div>
                <div className="subtitle" data-aos="fade-up">{ props.activities.acf.activities_subtitle }</div>
                <div className="content" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html: props.activities.acf.content }}></div>
                <div className="all-activities">
                {
                    props.allWordpressWpActivities.edges.map((item, index) => (
                        <div className="item tiles" key={item.node.id} data-aos="fade-up" data-aos-delay={ index * 200 } data-aos-duration="1200">
                            <div className="itemhover">
                                <div className="heading">{item.node.title}</div>
                                <div className="image">
                                    <img onClick={(event) => changeCurrentIndex(event,index)} src={item.node.featured_media.source_url} />
                                </div>
                                <div className="button">
                                <span onClick={(event) => changeCurrentIndex(event,index)}>{ props.activities.acf.more_info_button }</span>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
                <div className="more-activities-title">Or More...</div>
                <div className="main-content-wrapper">                    
                    <div className="main-content" dangerouslySetInnerHTML = {{ __html: props.activities.acf.main_content_left}}></div>
                    <div className="main-content" dangerouslySetInnerHTML = {{ __html: props.activities.acf.main_content_right}}></div>
                </div>
            </div>
            
            <div className="activities-popup fade-in" key={state.currentIndex !== null ? props.allWordpressWpActivities.edges[state.currentIndex].node.id : null} style={{display: state.currentIndex !== null ? 'flex' : 'none' }}>
                <div className="activities-popup-inner " ref={node}>
                    <div className="item-left">
                        <img src={ state.currentIndex !== null ? props.allWordpressWpActivities.edges[state.currentIndex].node.acf.large_image.source_url : null} alt="" />
                    </div>
                    <div className="item-right">
                        <img src={ state.currentIndex !== null ? props.allWordpressWpActivities.edges[state.currentIndex].node.acf.small_image.source_url : null} alt="" />
                    </div>
                    <div className="popup-title">{ state.currentIndex !== null ? props.allWordpressWpActivities.edges[state.currentIndex].node.title : null}</div> 
                    <div className="popup-content" dangerouslySetInnerHTML = {{ __html: state.currentIndex !== null ? props.allWordpressWpActivities.edges[state.currentIndex].node.acf.content : null}}></div> 
                </div>
            </div>

        </div>
    ) } />
};

export default Content;